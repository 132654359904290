@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;700&display=swap");
@font-face {
  font-family: "Sombra";
  src: url("/assets/fonts/Sombra-Bold.woff2") format("woff2"), url("/assets/fonts/Sombra-Bold.woff") format("woff");
  font-display: swap;
}
h1,
h2,
h3,
h4,
p {
  margin: 0;
}
span.row {
  display: block;
}
h1 {
  font-family: "Sombra", serif;
  font-size: 120px;
  line-height: 110px;
}
h2 {
  font-family: "Sombra", serif;
  font-size: 54px;
  line-height: 54px;
}
h3 {
  font-family: "Karla", sans-serif;
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
}
h3.topping {
  margin-bottom: 16px;
}
h3.topping::before {
  content: '';
  display: block;
  width: 100%;
  height: 24px;
  background: url("/assets/images/shapes/Wheel0.svg") no-repeat center;
  background-size: auto 100%;
  margin-bottom: 16px;
}
h3.topping.extraBottom {
  margin-bottom: 56px;
}
h3.topping.wheel1::before {
  background: url("/assets/images/shapes/Wheel1.svg") no-repeat center;
}
h3.topping.wheel2::before {
  background: url("/assets/images/shapes/Wheel2.svg") no-repeat center;
}
h3.topping.wheel3::before {
  background: url("/assets/images/shapes/Wheel3.svg") no-repeat center;
}
body,
p {
  font-family: "Karla", sans-serif;
  font-size: 18px;
  line-height: 1.4;
  font-weight: 400;
}
body.highlight,
p.highlight {
  font-weight: bold;
}
body.mention,
p.mention {
  font-size: 15px;
}
@media screen and (max-width: 760px) {
  h1 {
    font-size: 80px;
    line-height: 72px;
  }
}
@media screen and (max-width: 540px) {
  h1 {
    font-size: 64px;
    line-height: 56px;
  }
  h2 {
    font-size: 48px;
    line-height: 48px;
  }
  h3 {
    font-size: 32px;
    line-height: 32px;
  }
}
@media screen and (max-width: 440px) {
  h1 {
    font-size: 48px;
    line-height: 40px;
  }
  h2 {
    font-size: 40px;
    line-height: 40px;
  }
}
@media screen and (max-width: 360px) {
  h3 {
    font-size: 24px;
    line-height: 24px;
  }
}
body,
html {
  margin: 0;
  padding: 0;
}
body {
  background: #fff;
  color: #000;
}
a {
  font-family: "Karla", sans-serif;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
}
a.link {
  color: #d04235;
  position: relative;
}
a.link::after {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  bottom: -4px;
  height: 2px;
  background: #d04235;
  transform-origin: right;
  transform: scaleX(0);
  will-change: transform;
  transition: 0.2s transform ease-in-out;
}
a.link:hover::after {
  transform-origin: left;
  transform: scaleX(1);
}
a.primary {
  font-family: "Karla", sans-serif;
  font-weight: bold;
  font-size: 16px;
  position: relative;
  background: #000;
  color: #fff;
  padding: 12px 60px 12px 20px;
  border-radius: 40px;
}
a.primary.blue {
  color: #000;
}
a.primary.blue::before,
a.primary.blue::after {
  background: #000;
}
a.primary.red {
  background: #d04235;
}
a.primary.blue {
  background: #d0eaea;
}
a.primary::before,
a.primary::after {
  content: '';
  position: absolute;
  right: 20px;
  top: calc(50% - 10px);
  display: inline-block;
  width: 32px;
  height: 20px;
  background: #fff;
  -webkit-mask-image: url("/assets/images/icons/buttonarrow.svg");
          mask-image: url("/assets/images/icons/buttonarrow.svg");
  -webkit-mask-size: 100%;
          mask-size: 100%;
  -webkit-mask-repeat: none;
          mask-repeat: none;
  -webkit-mask-position: center;
          mask-position: center;
  will-change: transform, opacity;
  transition: 0.2s 0.1s transform ease-in-out, 0.2s 0.1s opacity ease-in-out;
}
a.primary::before {
  right: 52px;
  transform: scale(0.5);
  opacity: 0;
  transition: 0.2s transform ease-in-out, 0.2s opacity ease-in-out;
}
a.primary:hover::before {
  transform: translate(32px);
  opacity: 1;
  transition: 0.2s 0.1s transform ease-in-out, 0.2s 0.1s opacity ease-in-out;
}
a.primary:hover::after {
  transform: translate(32px) scale(0.5);
  opacity: 0;
  transition: 0.2s transform ease-in-out, 0.2s opacity ease-in-out;
}
footer {
  position: relative;
  z-index: 10;
  background: #000;
  color: #fff;
  padding: 80px 80px 40px;
  text-align: center;
}
footer p.highlight {
  margin: 16px 0 32px;
}
footer .social {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
footer .social .logos .logo {
  height: 40px;
  margin-right: 24px;
}
footer .social .links {
  display: flex;
  align-items: center;
}
footer .social .links .socialNetwork {
  height: 32px;
  margin-right: 24px;
}
footer .credits {
  margin-top: 32px;
}
footer .credits p {
  font-size: 15px;
  color: rgba(255,255,255,0.75);
}
footer .credits p a {
  color: rgba(255,255,255,0.75);
  font-weight: 400;
}
footer .credits p a:hover {
  color: #d04235;
}
@media screen and (max-width: 700px) {
  footer {
    padding: 80px 40px 40px;
  }
}
@media screen and (max-width: 600px) {
  footer .social,
  footer .social .links {
    flex-wrap: wrap;
    justify-content: center;
  }
}
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 100;
  background: #fff;
  left: 0;
  right: 0;
  top: 0;
  height: 80px;
  padding: 0px 80px;
  will-change: transform, opacity;
  transition: transform 0.3s cubic-bezier(0.77, 0, 0.18, 1), opacity 0.25s cubic-bezier(0.77, 0, 0.18, 1);
}
header.hidden {
  opacity: 0;
  transform: translateY(-32px);
}
header .logoLink {
  z-index: 5;
}
header .logoLink img.logo {
  height: 40px;
}
header nav {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-right: 24px;
}
header nav .linkBox {
  margin: 0 24px;
}
header nav .linkBox a.link {
  color: #000;
}
header nav a.primary {
  display: none;
}
header .menuIcon {
  display: none;
  width: 32px;
  height: 32px;
  padding: 16px 0 16px 16px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  background: none;
  border: none;
  box-sizing: content-box;
}
header .menuIcon.open .stripe:nth-of-type(1) {
  transform: rotate(-45deg) translateX(1px);
}
header .menuIcon.open .stripe:nth-of-type(2) {
  transform: translateX(-16px) scaleX(0);
}
header .menuIcon.open .stripe:nth-of-type(3) {
  transform: rotate(45deg) translateX(1px) scaleX(2);
}
header .menuIcon .stripe {
  width: 32px;
  height: 2px;
  background: #000;
  transform-origin: right;
  transition: transform 0.2s ease-in-out;
}
header .menuIcon .stripe:nth-of-type(2) {
  width: 24px;
}
header .menuIcon .stripe:nth-of-type(3) {
  width: 16px;
}
@media screen and (max-width: 1040px) {
  header .logoLink img.logo {
    margin: 8px 0 0;
    height: 32px;
  }
  header a.primary {
    display: none;
  }
  header nav {
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    visibility: none;
    pointer-events: none;
    position: fixed;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: calc(100vh - 160px);
    padding-bottom: 80px;
    background: #fff;
    margin-right: 0;
    opacity: 0;
    will-change: opacity;
    transition: opacity 0.5s cubic-bezier(0.77, 0, 0.18, 1);
  }
  header nav .linkBox {
    padding: 16px;
    margin: 16px 0 16px 64px;
    text-align: left;
    width: auto;
    transform: none !important;
  }
  header nav .linkBox a {
    font-family: "Sombra", serif;
    font-size: 32px;
    line-height: 32px;
  }
  header nav a.primary {
    margin: 32px 0 0 88px;
    display: inline-block;
  }
  header nav.open {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
  }
  header .menuIcon {
    display: flex;
  }
}
@media screen and (max-width: 700px) {
  header {
    padding: 0 40px;
  }
  header.hidden {
    opacity: 1;
    transform: translateY(0px);
  }
  header nav .linkBox {
    margin: 16px 24px;
  }
  header nav a.primary {
    margin: 32px 0 0 40px;
  }
}
@media screen and (max-width: 540px) {
  header {
    padding: 0 16px;
  }
  header nav .linkBox {
    margin: 8px 0px;
  }
  header nav a.primary {
    margin: 16px 0 0 16px;
  }
}
@media screen and (max-width: 360px) {
  header nav {
    height: calc(100vh - 120px);
    padding-bottom: 40px;
  }
}
@media screen and (pointer: fine) {
  header .menuIcon:hover .stripe:nth-of-type(2) {
    transform: scaleX(1.333333333333333);
  }
  header .menuIcon:hover .stripe:nth-of-type(3) {
    transform: scaleX(2);
  }
  header .menuIcon.open:hover .stripe:nth-of-type(2) {
    transform: translateX(-16px) scaleX(0);
  }
  header .menuIcon.open:hover .stripe:nth-of-type(3) {
    transform: rotate(45deg) translateX(1px) scaleX(2);
  }
}
#ambassadors {
  background: #fff9cd;
  background: url("/assets/images/grid-flat.svg") no-repeat center;
  background-size: cover;
  position: relative;
  z-index: 100;
}
#ambassadors .areaList {
  display: flex;
  padding: 20px;
  margin: 0 0 32px;
  overflow: scroll;
  justify-content: center;
  scrollbar-width: none;
}
#ambassadors .areaList::-webkit-scrollbar {
  width: 0px;
}
#ambassadors .areaList::-webkit-scrollbar-track {
  background: #fff9cd;
}
#ambassadors .areaList::-webkit-scrollbar-thumb {
  background: rgba(255,249,205,0.2);
}
#ambassadors .areaList::-webkit-scrollbar-thumb:hover {
  background: #fff9cd;
}
#ambassadors .areaList .area {
  cursor: pointer;
  box-sizing: content-box;
  background-color: transparent;
  font-family: "Karla", sans-serif;
  font-size: 18px;
  line-height: 1.4;
  font-weight: bold;
  color: #000;
  padding: 8px 16px;
  margin: 0 8px;
  border-radius: 24px;
  border: 1px solid #000;
}
#ambassadors .areaList .area.focus {
  background: #000;
  color: #fff;
}
#ambassadors .areaList .area:hover {
  transform: scale(1.03);
}
#ambassadors .ambassadorsList {
  display: flex;
  align-items: center;
  overflow-x: scroll;
  padding-left: 0px;
  scrollbar-width: none;
}
#ambassadors .ambassadorsList::-webkit-scrollbar {
  width: 0px;
}
#ambassadors .ambassadorsList::-webkit-scrollbar-track {
  background: #fff9cd;
}
#ambassadors .ambassadorsList::-webkit-scrollbar-thumb {
  background: rgba(255,249,205,0.2);
}
#ambassadors .ambassadorsList::-webkit-scrollbar-thumb:hover {
  background: #fff9cd;
}
#ambassadors .ambassadorsList .ambassadorsArea {
  margin-left: 160px;
}
#ambassadors .ambassadorsList .ambassadorsArea:first-of-type {
  padding-left: calc(25vw - 160px);
}
#ambassadors .ambassadorsList .ambassadorsArea:last-of-type {
  padding-right: 25vw;
}
#ambassadors .ambassadorsList .ambassadorsArea .ambassadorsAreaRow {
  display: flex;
}
#ambassadors .ambassadorsList .ambassadorsArea .ambassadorsAreaRow .ambassadorBox {
  position: relative;
  margin: 8px 16px;
}
#ambassadors .ambassadorsList .ambassadorsArea .ambassadorsAreaRow .ambassadorBox:hover a.socialLogo {
  opacity: 1;
  transform: translateY(0);
}
#ambassadors .ambassadorsList .ambassadorsArea .ambassadorsAreaRow .ambassadorBox img {
  width: 160px;
  height: 160px;
  border-radius: 80px;
  display: block;
  margin-bottom: 8px;
  background: #d04235;
}
#ambassadors .ambassadorsList .ambassadorsArea .ambassadorsAreaRow .ambassadorBox p {
  text-align: center;
}
#ambassadors .ambassadorsList .ambassadorsArea .ambassadorsAreaRow .ambassadorBox a.socialLogo {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #d04235;
  -webkit-mask-image: url("/assets/images/shapes/macaron_fullsize.svg");
          mask-image: url("/assets/images/shapes/macaron_fullsize.svg");
  -webkit-mask-size: 24px;
          mask-size: 24px;
  -webkit-mask-position: center 120px;
          mask-position: center 120px;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  opacity: 0;
  transform: translateY(8px);
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
#ambassadors .ambassadorsList .ambassadorsArea .ambassadorsAreaRow .ambassadorBox a.socialLogo.instagram {
  -webkit-mask-image: url("/assets/images/social/instagram.svg");
          mask-image: url("/assets/images/social/instagram.svg");
}
#ambassadors .ambassadorsList .ambassadorsArea .ambassadorsAreaRow .ambassadorBox a.socialLogo.linkedin {
  -webkit-mask-image: url("/assets/images/social/linkedin.svg");
          mask-image: url("/assets/images/social/linkedin.svg");
}
#ambassadors .ambassadorsList .ambassadorsArea .ambassadorsAreaRow .ambassadorBox a.socialLogo.twitter {
  -webkit-mask-image: url("/assets/images/social/twitter.svg");
          mask-image: url("/assets/images/social/twitter.svg");
}
#ambassadors .ambassadorsList .morePeopleFromENSCI {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 176px;
  width: 176px;
  margin: 0 8px;
  border-radius: 88px;
  padding: 32px;
  box-sizing: border-box;
  flex: 1 0 auto;
  background: #d04235;
  -webkit-mask-image: url("/assets/images/shapes/macaron.svg");
          mask-image: url("/assets/images/shapes/macaron.svg");
  -webkit-mask-size: 140%;
          mask-size: 140%;
  -webkit-mask-repeat: none;
          mask-repeat: none;
  -webkit-mask-position: center;
          mask-position: center;
  transition: -webkit-mask-size 0.3s ease-in-out;
  transition: mask-size 0.3s ease-in-out;
  transition: mask-size 0.3s ease-in-out, -webkit-mask-size 0.3s ease-in-out;
}
#ambassadors .ambassadorsList .morePeopleFromENSCI:hover {
  -webkit-mask-size: 190%;
          mask-size: 190%;
}
#ambassadors .ambassadorsList .morePeopleFromENSCI span {
  color: #fff;
}
#ambassadors .ambassadorsList .morePeopleFromENSCI span:first-of-type::before {
  content: '';
  display: block;
  margin: 0 auto 8px 0;
  width: 32px;
  height: 20px;
  background: #fff;
  -webkit-mask-image: url("/assets/images/icons/buttonarrow.svg");
          mask-image: url("/assets/images/icons/buttonarrow.svg");
  -webkit-mask-size: 100%;
          mask-size: 100%;
  -webkit-mask-repeat: none;
          mask-repeat: none;
  -webkit-mask-position: center;
          mask-position: center;
}
@media screen and (max-width: 540px) {
  #ambassadors .areaList {
    justify-content: unset;
  }
  #ambassadors .areaList .area {
    flex: 1 0 auto;
  }
  #ambassadors .ambassadorsList .ambassadorsArea:first-of-type {
    padding-left: 32px;
    margin-left: 0;
  }
}
@media screen and (min-width: 1500px) {
  #ambassadors .ambassadorsList .ambassadorsArea {
    margin-left: 240px;
  }
  #ambassadors .ambassadorsList .ambassadorsArea:first-of-type {
    padding-left: calc(25vw - 240px);
  }
}
@media screen and (min-width: 1700px) {
  #ambassadors .ambassadorsList .ambassadorsArea {
    margin-left: 320px;
  }
  #ambassadors .ambassadorsList .ambassadorsArea:first-of-type {
    padding-left: calc(25vw - 320px);
  }
}
.homeContent {
  position: relative;
  z-index: 10;
}
#ambassadors,
#news,
#words {
  padding: 80px 0;
}
#hero {
  position: relative;
  padding-top: 80px;
  height: 100vh;
  box-sizing: border-box;
}
#hero .hero-background {
  position: fixed;
  z-index: -1;
  top: 80px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  width: calc(100% - 40px);
  height: calc(100vh - 100px);
  -o-object-fit: cover;
     object-fit: cover;
}
#hero .contentBox {
  display: flex;
  position: relative;
  z-index: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: calc(100vh - 100px);
  padding: 0 20px;
}
#hero .contentBox p {
  margin: 12px 8px 60px;
}
#hero .contentBox span.logo {
  position: relative;
}
#hero .contentBox span.logo::after {
  content: '';
  display: block;
  position: absolute;
  left: 10%;
  right: 10%;
  bottom: 10%;
  height: 5%;
  background: #d04235;
  -webkit-animation: logoLoading 2s cubic-bezier(0.77, 0, 0.18, 1) 3;
          animation: logoLoading 2s cubic-bezier(0.77, 0, 0.18, 1) 3;
}
#hero .pictureHidder {
  position: fixed;
  z-index: 1;
  right: 0;
  left: 0;
  top: 0;
  height: 100vh;
  background: #fff;
  transform-origin: bottom;
  transform: scaleY(0);
  will-change: transform;
}
@media screen and (max-width: 360px) {
  #hero .hero-background {
    left: 8px;
    right: 8px;
    bottom: 8px;
    width: calc(100% - 16px);
    height: calc(100vh - 88px);
  }
}
@-webkit-keyframes logoLoading {
  from {
    transform: scaleX(1);
    transform-origin: right;
  }
  49.5% {
    transform: scaleX(0);
    transform-origin: right;
  }
  50.5% {
    transform: scaleX(0);
    transform-origin: left;
  }
  to {
    transform: scaleX(1);
    transform-origin: left;
  }
}
@keyframes logoLoading {
  from {
    transform: scaleX(1);
    transform-origin: right;
  }
  49.5% {
    transform: scaleX(0);
    transform-origin: right;
  }
  50.5% {
    transform: scaleX(0);
    transform-origin: left;
  }
  to {
    transform: scaleX(1);
    transform-origin: left;
  }
}
#missions {
  height: 380vh;
  background: linear-gradient(to bottom, #fff, #fff 23%, #fff9cd 43%, #fff9cd 56%, #eee7d6 76%);
}
#missions .singleMission {
  pointer-events: none;
  position: fixed;
  z-index: 5;
  left: 80px;
  top: 0;
  height: 100vh;
  width: calc(50vw - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  will-change: opacity;
  transition: 0.3s opacity cubic-bezier(0.77, 0, 0.18, 1);
}
#missions .singleMission.focused {
  pointer-events: auto;
  opacity: 1;
}
#missions .singleMission.opened h2 {
  transform: translateY(0) !important;
}
#missions .singleMission.opened p {
  opacity: 1;
}
#missions .singleMission h2 {
  will-change: transform;
  transition: transform 0.3s cubic-bezier(0.77, 0, 0.18, 1);
}
#missions .singleMission p {
  margin: 16px 0;
  opacity: 0;
  will-change: opacity;
  transition: opacity 0.3s 0.1s cubic-bezier(0.77, 0, 0.18, 1);
}
#missions .missionsCanvas {
  pointer-events: none;
  position: fixed;
  z-index: 1;
  right: 0;
  bottom: 0;
  top: 0;
  height: 100vh;
  width: 50vw;
  opacity: 0;
  will-change: opacity;
}
#missions .missionsCanvas .funShape {
  position: absolute;
  z-index: 3;
  width: 8vh;
}
#missions .missionsCanvas .funShape:nth-of-type(1) {
  left: 5vw;
  top: 80vh;
}
#missions .missionsCanvas .funShape:nth-of-type(2) {
  left: 10vw;
  top: 0vh;
}
#missions .missionsCanvas .funShape:nth-of-type(3) {
  right: 0vw;
  top: 20vh;
}
#missions .missionsCanvas .orangeBubble,
#missions .missionsCanvas .dialogueBubble,
#missions .missionsCanvas .dialogueHalfBubble {
  position: absolute;
  left: calc(50% - 20vh);
  top: calc(50% - 20vh);
  width: 40vh;
  height: 40vh;
}
#missions .missionsCanvas .orangeBubble {
  z-index: 5;
  transform: translateY(-75vh);
}
#missions .missionsCanvas .orangeBubble .alumniMacaron {
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  -webkit-mask-image: url("/assets/images/shapes/macaron_fullsize.svg");
  mask-image: url("/assets/images/shapes/macaron_fullsize.svg");
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-size: 100%;
          mask-size: 100%;
}
#missions .missionsCanvas .orangeBubble .alumniMacaron .alumnisMacaronContent {
  text-align: center;
}
#missions .missionsCanvas .orangeBubble .alumniMacaron .macaronBackground {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 300%;
  width: 100%;
  background: linear-gradient(to bottom, #d04235, #d04235 50%, #f2722b 66%, #f2722b);
  will-change: transform;
  transform: translateY(-10%);
}
#missions .missionsCanvas .alumniBrownCircle {
  position: absolute;
  width: 20vh;
  height: 20vh;
  right: 10vw;
  top: calc(50% - 10vh);
  border-radius: 50%;
  overflow: hidden;
}
#missions .missionsCanvas .alumniBrownCircle .alumniBrownCircleBackground {
  width: 100%;
  height: 300%;
  background: linear-gradient(to bottom, #eee7d6, #c4a16c);
  will-change: transform;
}
#missions .missionsCanvas .dialogueBubble {
  z-index: 10;
  background: #d0eaea;
  border-radius: 50%;
  transform: translateY(75vh);
}
#missions .missionsCanvas .dialogueHalfBubble {
  z-index: 20;
  background: #f2722b;
  height: 20vh;
  border-radius: 20vh 20vh 0 0;
  transform-origin: bottom center;
  will-change: transform;
}
#missions .missionsCanvas .bubblesGrid {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
}
#missions .missionsCanvas .bubblesGrid .column .bubble {
  margin: 2vh 0 0 2vh;
  width: 20vh;
  height: 20vh;
  background: #fff9cd;
  border-radius: 50%;
}
#missions .missionsCanvas .bubblesGrid .column .bubble.green {
  background: #246763;
}
#missions .missionsCanvas .bubblesGrid .column .bubble.red {
  background: #d04235;
}
#missions .missionsCanvas .bubblesGrid .column .bubble.brown-star {
  background: url("/assets/images/shapes/sun.svg") no-repeat center;
  background-size: 33%;
  background-color: #c4a16c;
}
#missions .missionsCanvas .bubblesGrid .column .bubble.main {
  position: relative;
  z-index: 10;
  background: #d0eaea;
  transform: translate(calc(32vh - 25vw), calc(32vh - 50vh)) scale(2) rotate(45deg);
  will-change: transform;
}
#missions .missionsCanvas .bubblesGrid .column .bubble.main::after {
  content: '';
  display: block;
  width: 100%;
  height: 50%;
  border-radius: 20vh 20vh 0 0;
  background: #f2722b;
}
#missions .missionsCanvas .bubblesGrid .column .bubble.black-star {
  background: #000;
  -webkit-mask-image: url("/assets/images/shapes/macaron_fullsize.svg");
  mask-image: url("/assets/images/shapes/macaron_fullsize.svg");
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-size: 100%;
          mask-size: 100%;
}
@media screen and (max-width: 760px) {
  #missions .singleMission {
    width: calc(70vw - 80px);
  }
}
@media screen and (max-width: 700px) {
  #missions .singleMission,
  #missions .missionsCanvas {
    height: calc(50vh - 40px);
  }
  #missions .singleMission {
    top: 0;
    left: 0;
    width: 100vw;
    padding: 96px 40px 16px;
    box-sizing: border-box;
    height: auto;
    background: linear-gradient(to bottom, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.5) 80%, rgba(255,255,255,0) 100%);
  }
  #missions .missionsCanvas {
    top: unset;
    left: 0;
    width: 100vw;
  }
}
@media screen and (max-width: 600px) {
  #missions .singleMission {
    padding: 96px 16px 16px;
  }
}
@media screen and (min-width: 1500px) {
  #missions .singleMission {
    left: 160px;
    width: calc(50vw - 160px);
    max-width: 660px;
  }
  #missions .missionsCanvas {
    right: 80px;
  }
}
@media screen and (min-width: 1700px) {
  #missions .singleMission {
    left: 240px;
    width: calc(50vw - 240px);
  }
  #missions .missionsCanvas {
    right: 160px;
  }
}
#news {
  background: #eee7d6;
}
#news .calendar {
  display: flex;
  justify-content: space-between;
  padding: 0 80px;
}
#news .calendar .calendarPreview,
#news .calendar .calendarListContainer,
#news .calendar .calendarList {
  width: calc(50% - 20px);
  height: calc(100vh - 240px);
}
#news .calendar .calendarPreview img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#news .calendar .calendarListContainer {
  position: relative;
}
#news .calendar .calendarListContainer::before,
#news .calendar .calendarListContainer::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  background: linear-gradient(to bottom, #eee7d6, rgba(238,231,214,0.7), rgba(238,231,214,0));
}
#news .calendar .calendarListContainer::before {
  top: 0;
  height: 40px;
}
#news .calendar .calendarListContainer::after {
  height: 80px;
  bottom: 0;
  transform: rotate(180deg);
}
#news .calendar .calendarListContainer .calendarList {
  overflow: auto;
  width: 100%;
  box-sizing: border-box;
/* width */
}
#news .calendar .calendarListContainer .calendarList.locked {
  overflow: hidden;
}
#news .calendar .calendarListContainer .calendarList::-webkit-scrollbar {
  width: 8px;
}
#news .calendar .calendarListContainer .calendarList::-webkit-scrollbar-track {
  background: #eee7d6;
}
#news .calendar .calendarListContainer .calendarList::-webkit-scrollbar-thumb {
  background: rgba(196,161,108,0.2);
}
#news .calendar .calendarListContainer .calendarList::-webkit-scrollbar-thumb:hover {
  background: #c4a16c;
}
#news .calendar .calendarListContainer .calendarList .calendarEvent {
  display: block;
  color: #000;
  padding: 20px 0;
  border-bottom: 2px solid #000;
}
#news .calendar .calendarListContainer .calendarList .calendarEvent:last-of-type {
  margin-bottom: 20px;
}
#news .calendar .calendarListContainer .calendarList .calendarEvent h2 {
  margin-bottom: 20px;
}
#news .calendar .calendarListContainer .calendarList a.calendarEvent {
  position: relative;
  z-index: 2;
}
#news .calendar .calendarListContainer .calendarList a.calendarEvent::after {
  content: '';
  display: block;
  width: 100%;
  position: absolute;
  z-index: -1;
  bottom: -2px;
  left: 0;
  height: 4px;
  background: #d04235;
  transform: scaleY(0);
  transform-origin: top;
  will-change: transform;
  transition: transform 0.3s ease-in-out;
}
#news .calendar .calendarListContainer .calendarList a.calendarEvent:hover::after {
  transform-origin: bottom;
  transform: scaleY(1);
}
@media screen and (max-width: 760px) {
  #news .calendar {
    flex-direction: column;
  }
  #news .calendar .calendarList,
  #news .calendar .calendarListContainer,
  #news .calendar .calendarPreview {
    width: 100%;
  }
  #news .calendar .calendarPreview {
    height: calc((100vw - 160px) / 2);
  }
  #news .calendar .calendarListContainer {
    margin-top: 40px;
  }
}
@media screen and (max-width: 700px) {
  #news .calendar {
    padding: 0 40px;
  }
  #news .calendar .calendarList,
  #news .calendar .calendarListContainer,
  #news .calendar .calendarPreview {
    height: calc(100vh - 160px);
  }
  #news .calendar .calendarPreview {
    height: calc((100vw - 80px) / 2);
  }
  #news .calendar .calendarListContainer {
    margin-top: 20px;
  }
}
#words .ressources {
  position: relative;
  padding: 0 80px;
  overflow: hidden;
}
#words .ressources .navSide {
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  width: 10vw;
  max-width: 160px;
}
#words .ressources .navSide.right {
  cursor: url("/assets/images/icons/buttonarrow_right.svg"), auto;
  right: 0;
}
#words .ressources .navSide.left {
  cursor: url("/assets/images/icons/buttonarrow_left.svg"), auto;
  left: 0;
}
#words .ressources .ressourcesContainer {
  display: inline-flex;
  will-change: transform;
  transition: transform 0.4s cubic-bezier(0.77, 0, 0.18, 1);
}
#words .ressources .ressourcesContainer.dragging {
  transition: none;
}
#words .ressources .ressourcesContainer .ressource {
  color: #000;
  margin-right: 60px;
  flex: 0 0 30vw;
  width: 30vw;
  min-width: 240px;
}
#words .ressources .ressourcesContainer .ressource:hover .ressourceImageContainer img {
  transform: scale(1.1);
}
#words .ressources .ressourcesContainer .ressource .ressourceImageContainer {
  width: 100%;
  height: 30vw;
  min-height: 240px;
  overflow: hidden;
}
#words .ressources .ressourcesContainer .ressource .ressourceImageContainer img {
  width: 100%;
  will-change: transform;
  transition: transform 0.4s ease-in-out;
}
#words .ressources .ressourcesContainer .ressource p.highlight {
  margin-top: 16px;
}
@media screen and (pointer: coarse) and (max-width: 440px) {
  #words .ressources .navSide {
    display: none;
  }
}
@media screen and (max-width: 700px) {
  #words .ressources {
    padding: 0 40px 0;
  }
}
@media screen and (min-width: 1500px) {
  #words .ressources .ressourcesContainer .ressource {
    flex: 0 0 25vw;
    width: 25vw;
  }
  #words .ressources .ressourcesContainer .ressource .ressourceImageContainer {
    height: 25vw;
  }
}
@media screen and (min-width: 1700px) {
  #words .ressources .ressourcesContainer .ressource {
    flex: 0 0 20vw;
    width: 20vw;
  }
  #words .ressources .ressourcesContainer .ressource .ressourceImageContainer {
    height: 20vw;
  }
}

/*# sourceMappingURL=main.css.map */
